import { useEffect, useMemo } from "react";
import useSnackbarError from "hooks/useSnackbarError";
import { useContractWrite, useWaitForTransaction } from "wagmi";
import useSnackbarSuccess from "hooks/useSnackbarSuccess";
import { BigNumberish } from "ethers";
import useNftContract from "hooks/useNftContract";

const useMint = (
  donationAmount?: BigNumberish,
  onSuccess?: () => void
): [() => void, boolean | undefined] => {
  const nftContract = useNftContract();

  const [{ data: mintData, loading: isLoading, error: writeError }, mint] =
    useContractWrite(
      {
        addressOrName: nftContract!.address,
        contractInterface: nftContract!.interface,
      },
      "mint",
      {
        overrides: {
          value: donationAmount?.toString(),
        },
      }
    );

  const [{ data: waitData, loading: isWaiting, error: waitError }] =
    useWaitForTransaction({
      wait: mintData?.wait,
    });

  useSnackbarError(writeError);
  useSnackbarError(waitError);
  useSnackbarSuccess(waitData);

  useEffect(() => {
    if (!isWaiting && waitData && onSuccess) {
      onSuccess();
    }
  }, [isWaiting, waitData, onSuccess]);

  return useMemo(
    () => [mint, isLoading || isWaiting],
    [mint, isWaiting, isLoading]
  );
};

export default useMint;
