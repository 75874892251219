import { ContractConfig } from "types";
import { chain } from "wagmi";
import nftsForUkraineAbi from "./abis/nftsForUkraine.abi.json";
import en from "./locales/en.json";

export { default as theme } from "./theme";

export const chainNames = process.env.REACT_APP_CHAIN_NAMES!.split(",");
export const infuraId = process.env.REACT_APP_INFURA_ID;

export const publicUrl = process.env.PUBLIC_URL;

// @ts-expect-error
export const chains = chainNames.map((name) => chain[name]);

export const mainnetNftContract: ContractConfig = {
  address: process.env.REACT_APP_MAINNET_NFT_ADDRESS!,
  interface: nftsForUkraineAbi,
};

export const polygonNftContract: ContractConfig = {
  address: process.env.REACT_APP_POLYGON_NFT_ADDRESS!,
  interface: nftsForUkraineAbi,
};

export const locales = {
  en,
};
