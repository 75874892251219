import React from "react";

const MaticIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_68_98)">
      <path
        d="M14.1126 7.27778C13.8222 7.11279 13.443 7.11279 13.1111 7.27778L10.7704 8.65067L9.18222 9.52273L6.88296 10.8956C6.59259 11.0606 6.21333 11.0606 5.88148 10.8956L4.08593 9.81734C3.79556 9.65236 3.58222 9.3165 3.58222 8.94529V6.86532C3.58222 6.53535 3.74815 6.19949 4.08593 5.99327L5.88148 4.95623C6.17185 4.79125 6.55111 4.79125 6.88296 4.95623L8.67852 6.03451C8.96889 6.19949 9.18222 6.53535 9.18222 6.90657V8.27946L10.7704 7.36027V5.94613C10.7704 5.61616 10.6044 5.2803 10.2667 5.07407L6.93037 3.12374C6.64 2.95875 6.26074 2.95875 5.92889 3.12374L2.5037 5.12121C2.16593 5.2862 2 5.61616 2 5.95202V9.85859C2 10.1886 2.16593 10.5244 2.5037 10.7306L5.88741 12.681C6.17778 12.846 6.55704 12.846 6.88889 12.681L9.18815 11.3493L10.7763 10.436L13.0756 9.10438C13.3659 8.93939 13.7452 8.93939 14.077 9.10438L15.8726 10.1414C16.163 10.3064 16.3763 10.6423 16.3763 11.0135V13.0875C16.3763 13.4175 16.2104 13.7534 15.8726 13.9596L14.1185 14.9966C13.8281 15.1616 13.4489 15.1616 13.117 14.9966L11.3215 13.9596C11.0311 13.7946 10.8178 13.4588 10.8178 13.0875V11.7559L9.22963 12.681V14.0539C9.22963 14.3838 9.39556 14.7197 9.73333 14.9259L13.117 16.8763C13.4074 17.0412 13.7867 17.0412 14.1185 16.8763L17.5022 14.9259C17.7926 14.7609 18.0059 14.4251 18.0059 14.0539V10.1061C18.0059 9.77609 17.84 9.44024 17.5022 9.23401L14.1126 7.27778Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_68_98">
        <rect width="16" height="14" fill="white" transform="translate(2 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default MaticIcon;
