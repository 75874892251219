/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useCallback, useMemo } from "react";
import LocaleContext from "contexts/LocaleContext";
import { substitute } from "utils/stringUtils";

type LocaleProviderProps = {
  translations: { [key: string]: string };
};

const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
  translations,
  children,
}) => {
  const translate = useCallback(
    (key: string, values?: { [key: string]: any }) =>
      substitute(translations[key] || "", values),
    [translations]
  );

  const contextValue = useMemo(
    () => ({
      translate,
      translations,
    }),
    [translate]
  );

  return (
    <LocaleContext.Provider value={contextValue}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
