import { useMemo } from "react";
import { useAccount } from "wagmi";

const useConnectedAccount = (): [
  address: string | undefined,
  disconnect: () => void
] => {
  const [{ data: accountData }, disconnect] = useAccount();

  return useMemo(
    () => [accountData?.address, disconnect],
    [accountData?.address, disconnect]
  );
};

export default useConnectedAccount;
