import * as React from "react";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { Input, InputProps } from "@chakra-ui/input";

export type BigNumberInputProps = {
  value: BigNumberish;
  max?: BigNumberish;
  min?: BigNumberish;
  decimals?: number;
  onChange: (value: BigNumberish) => void;
} & Omit<InputProps, "onChange" | "max" | "min" | "value">;

export const BigNumberInput: React.FC<BigNumberInputProps> = ({
  value,
  max,
  min = "0",
  decimals = 18,
  onChange,
  ...rest
}) => {
  const inputRef = React.useRef<any>(null);

  const [inputValue, setInputValue] = React.useState("");

  React.useEffect(() => {
    if (!value) {
      setInputValue("");
    } else {
      let parseInputValue;
      try {
        parseInputValue = parseUnits(inputValue || "0", decimals);
      } catch {}

      if (!parseInputValue || !parseInputValue.eq(value)) {
        setInputValue(formatUnits(value, decimals));
      }
    }
  }, [value, decimals, inputValue]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;

    if (value === "") {
      onChange("0");
      setInputValue(value);
      return;
    }

    let newValue: BigNumber;
    try {
      newValue = parseUnits(value, decimals);
    } catch {
      return;
    }

    const isInvalid = (min && newValue.lt(min)) || (max && newValue.gt(max));
    if (isInvalid) {
      return;
    }

    setInputValue(value);
    onChange(newValue);
  };

  return (
    <Input value={inputValue} ref={inputRef} onChange={updateValue} {...rest} />
  );
};
