import React from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Divider,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import FanadiseLogo from "components/FanadiseLogo";
import useTranslate from "hooks/useTranslate";
import { FaExternalLinkAlt } from "react-icons/fa";
import { mainnetNftContract, polygonNftContract } from "config";

const Footer: React.FC = () => {
  const translate = useTranslate();

  return (
    <Box as="footer" mt="auto" bg="secondary.500" py={4}>
      <Container>
        <HStack
          w="full"
          align="center"
          justify="center"
          spacing={{
            base: "4",
            sm: "6",
          }}
        >
          <VStack>
            <Heading fontSize="xs">Contracts</Heading>

            <Button
              size="sm"
              as="a"
              href={`https://etherscan.io/address/${mainnetNftContract.address}`}
              target="_blank"
              variant="outline"
              rightIcon={<Icon as={FaExternalLinkAlt} />}
            >
              <Text textAlign="center" fontWeight="semibold">
                Ethereum
              </Text>
            </Button>

            <Button
              size="sm"
              as="a"
              href={`https://polygonscan.com/address/${polygonNftContract.address}`}
              target="_blank"
              variant="outline"
              borderColor="dark.500"
              borderRadius="full"
              rightIcon={<Icon as={FaExternalLinkAlt} />}
            >
              <Text textAlign="center" fontWeight="semibold">
                Polygon
              </Text>
            </Button>
          </VStack>

          <VStack>
            <Heading fontSize="xs">Updates</Heading>

            <Button
              size="sm"
              as="a"
              href="https://twitter.com/jakubchmielniak"
              target="_blank"
              variant="outline"
              borderColor="dark.500"
              borderRadius="full"
            >
              <Text textAlign="center" fontWeight="semibold">
                @jakubchmielniak
              </Text>
            </Button>

            <Button
              size="sm"
              as="a"
              href="https://twitter.com/barteksibiga"
              target="_blank"
              variant="outline"
            >
              <Text textAlign="center" fontWeight="semibold">
                @BartekSibiga
              </Text>
            </Button>
          </VStack>
        </HStack>

        <HStack
          w="full"
          align="center"
          justify="center"
          spacing={{
            base: "4",
            sm: "6",
          }}
        ></HStack>

        <Divider my="4" />

        <LinkBox as={Center}>
          <Text fontSize="sm" fontWeight="semibold">
            {translate("footer:powered")}
          </Text>
          <LinkOverlay href="//fanadise.com" target="_blank">
            <FanadiseLogo width="115" />
          </LinkOverlay>
        </LinkBox>
      </Container>
    </Box>
  );
};

export default Footer;
