import React from "react";
import { WagmiProvider, InjectedConnector, Connector } from "wagmi";
import { ExternalProvider } from "@ethersproject/providers";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import AccountGuard from "guards/AccountGuard";
import ConnectPage from "pages/ConnectPage";
import HomePage from "pages/HomePage";
import LocaleProvider from "providers/LocaleProvider";
import { providers } from "ethers";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { chains, infuraId, locales, theme } from "./config";
import Background from "components/Background";
import Layout from "components/Layout";
import WalletErrors from "components/WalletErrors";

const connectors = () => {
  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        infuraId,
        qrcode: true,
      },
    }),
  ];
};

const provider = ({
  connector,
  chainId,
}: {
  connector?: Connector;
  chainId?: number;
}) => {
  if (connector instanceof InjectedConnector) {
    return new providers.Web3Provider(
      connector.getProvider()! as ExternalProvider
    );
  }
  const network = providers.InfuraProvider.getNetwork(chainId!);
  if (network.ensAddress) {
    return new providers.InfuraProvider(network, infuraId);
  }
  return providers.getDefaultProvider();
};

const extendedTheme = extendTheme(theme);

const App: React.FC = () => (
  <ChakraProvider theme={extendedTheme}>
    <WagmiProvider autoConnect provider={provider} connectors={connectors}>
      <LocaleProvider translations={locales.en}>
        <Background />

        <AccountGuard
          yeyElement={
            <Layout>
              <HomePage />
              <WalletErrors />
            </Layout>
          }
          neyElement={
            <Layout>
              <ConnectPage />
            </Layout>
          }
        />
      </LocaleProvider>
    </WagmiProvider>
  </ChakraProvider>
);

export default App;
