import React from "react";
import ErrorPopup from "components/ErrorPopup";
import { Button, VStack } from "@chakra-ui/react";
import { useConnect, useNetwork } from "wagmi";
import { chains } from "config";
import useTranslate from "hooks/useTranslate";

const WalletErrors: React.FC = () => {
  const translate = useTranslate();
  const [
    {
      data: { connected: isConnected },
    },
  ] = useConnect();
  const [network, switchNetwork] = useNetwork();
  const isWrongNetworkErrorOpen =
    isConnected && !!network.data.chain?.unsupported;

  return (
    <>
      <ErrorPopup
        isOpen={isWrongNetworkErrorOpen}
        title={translate("wallet:wrongChain:title")}
        description={translate("wallet:wrongChain:description")}
      >
        {switchNetwork && (
          <VStack spacing="4" w="full">
            <Button
              onClick={() => {
                switchNetwork(chains[0].id);
              }}
              w="full"
              size="sm"
              colorScheme="secondary"
            >
              {translate("wallet:wrongChain:switch", {
                chainName: chains[0].name,
              })}
            </Button>

            <Button
              onClick={() => {
                switchNetwork(chains[1].id);
              }}
              w="full"
              size="sm"
              colorScheme="secondary"
            >
              {translate("wallet:wrongChain:switch", {
                chainName: chains[1].name,
              })}
            </Button>
          </VStack>
        )}
      </ErrorPopup>
    </>
  );
};

export default WalletErrors;
