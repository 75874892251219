import React from "react";
import { Box } from "@chakra-ui/react";

const Background: React.FC = () => (
  <Box
    position="fixed"
    w="100vmax"
    h="100vmax"
    backgroundColor="bright.500"
    backgroundImage={`url("bg.png")`}
    backgroundSize="cover"
    backgroundPosition="center"
    backgroundAttachment="fixed"
    zIndex={-1}
  />
);

export default Background;
