import React, { useState } from "react";
import { Box, Button, Container, HStack, Image, Text } from "@chakra-ui/react";
import { getShortenedAddress } from "utils/walletUtils";
import useConnectedAccount from "hooks/useConnectedAccount";
import WalletPopup from "components/WalletPopup";
import useAssets from "hooks/useAssets";
import CurrencyIcon from "components/CurrencyIcon";
import { useNetwork } from "wagmi";
import { publicUrl } from "config";

const Header: React.FC = () => {
  const [address] = useConnectedAccount();
  const [{ data: networkData }] = useNetwork();
  const { formattedBalance } = useAssets();
  const [isWalletDialogVisible, setIsWalletDialogVisible] = useState(false);

  return (
    <>
      <Box as="header" bg="dark.500" color="bright.400" py="4">
        <Container>
          <HStack justify="space-between" overflow="hidden">
            <Image
              src={`${publicUrl}/logo_white.png`}
              w={{
                base: "120px",
                sm: "160px",
                md: "180px",
              }}
            />

            {address && networkData && (
              <HStack spacing="6">
                <HStack gap="0.5">
                  <CurrencyIcon
                    symbol={networkData?.chain?.nativeCurrency?.symbol}
                    w="5"
                    h="auto"
                  />
                  <Text>
                    {formattedBalance ? formattedBalance.substring(0, 6) : "–"}
                  </Text>
                </HStack>

                <Button
                  borderColor="dark.400"
                  _hover={{
                    borderColor: "secondary.500",
                  }}
                  borderRadius="full"
                  variant="outline"
                  size="sm"
                  onClick={() => setIsWalletDialogVisible(true)}
                >
                  <Text fontWeight="600" fontSize="sm">
                    {getShortenedAddress(address, 3)}
                  </Text>
                </Button>
              </HStack>
            )}
          </HStack>
        </Container>
      </Box>

      {address && (
        <WalletPopup
          isOpen={isWalletDialogVisible}
          onClose={() => setIsWalletDialogVisible(false)}
        />
      )}
    </>
  );
};

export default Header;
