import { BigNumber } from "ethers";
import React from "react";

type AssetsContextValue = {
  balance?: BigNumber;
  formattedBalance?: string;
  symbol?: string;
};

const AssetsContext = React.createContext<AssetsContextValue>({
  balance: undefined,
  formattedBalance: undefined,
  symbol: undefined,
});

export default AssetsContext;
