/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import useTranslate from "hooks/useTranslate";
import useSnackbar from "hooks/useSnackbar";

const useSnackbarError = (error?: Error) => {
  const translate = useTranslate();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (error) {
      snackbar("error", error.message || translate("error:default"));
    }
  }, [error]);
};

export default useSnackbarError;
