/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useMemo } from "react";
import AssetsContext from "contexts/AssetsContext";
import useConnectedAccount from "hooks/useConnectedAccount";
import { useBalance } from "wagmi";

const AssetsProvider: FunctionComponent = ({ children }) => {
  const [address] = useConnectedAccount();
  const [{ data: balanceData }] = useBalance({
    addressOrName: address,
  });

  const contextValue = useMemo(
    () => ({
      balance: balanceData?.value,
      formattedBalance: balanceData?.formatted,
      symbol: balanceData?.symbol,
    }),
    [balanceData]
  );

  return (
    <AssetsContext.Provider value={contextValue}>
      {children}
    </AssetsContext.Provider>
  );
};

export default AssetsProvider;
