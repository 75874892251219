export const substitute = (
  str: string,
  values?: { [key: string]: any }
): string => {
  let result = str || "";

  if (values) {
    for (const [valueKey, value] of Object.entries(values)) {
      result = result.replace(new RegExp(`{${valueKey}}`, "g"), value);
    }
  }

  return result;
};
