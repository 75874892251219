import React from "react";

type LocaleContextValue = {
  translate: (key: string, values?: { [key: string]: any }) => string;
  translations?: object;
};

const LocaleContext = React.createContext<LocaleContextValue | null>(null);

export default LocaleContext;
