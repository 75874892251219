import { useConnect } from "wagmi";
import { Center, Spinner } from "@chakra-ui/react";

type AccountGuardProps = {
  yeyElement: JSX.Element;
  neyElement: JSX.Element;
};

const AccountGuard = ({ yeyElement, neyElement }: AccountGuardProps) => {
  const [
    {
      data: { connected: isConnected },
      loading: isLoading,
    },
  ] = useConnect();

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner size="xl" color="primary.500" thickness="4px" />
      </Center>
    );
  }

  return isConnected ? yeyElement : neyElement;
};

export default AccountGuard;
