/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useMemo } from "react";
import NftContractContext, {
  NftContractContextValue,
} from "contexts/NftContractContext";
import { useNetwork } from "wagmi";
import { mainnetNftContract, polygonNftContract } from "config";

const NftContractProvider: FunctionComponent = ({ children }) => {
  const [{ data: networkData }] = useNetwork();

  const contextValue = useMemo<NftContractContextValue>(() => {
    if (!networkData.chain?.id) {
      return undefined;
    }

    let nftContract = undefined;
    if ([1, 4].includes(networkData.chain?.id)) {
      nftContract = mainnetNftContract;
    } else if ([137, 80001].includes(networkData.chain?.id)) {
      nftContract = polygonNftContract;
    }

    return nftContract;
  }, [networkData]);

  return (
    <NftContractContext.Provider value={contextValue}>
      {children}
    </NftContractContext.Provider>
  );
};

export default NftContractProvider;
