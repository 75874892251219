import { SystemStyleFunction } from "@chakra-ui/theme-tools";

const theme = {
  styles: {
    global: {
      body: {
        overflowX: "hidden",
        backgroundColor: "bright",
        fontWeight: "500",
        color: "dark.500",
      },
      "html, body, #root": {
        height: "full",
        fontSize: {
          base: "14px",
          md: "16px",
        },
      },
      "*:focus": {
        boxShadow: "none !important",
      },
      "*": {
        webkitTapHighlightColor: "transparent",
      },
    },
  },
  fonts: {
    heading: "Montserrat",
    body: "Montserrat",
  },
  colors: {
    primary: {
      400: "#3d6ce8",
      500: "#3d6ce8",
      600: "#325ed4",
      700: "#325ed4",
    },
    secondary: {
      400: "#ffdc5a",
      500: "#ffdc5a",
      600: "#eecc4c",
      700: "#eecc4c",
    },
    dark: {
      300: "#8b8b95",
      400: "#333336",
      500: "#212123",
      600: "#141415",
    },
    bright: {
      400: "#FFF",
      500: "#FFF",
      600: "FFF",
    },
  },
  components: {
    Container: {
      baseStyle: {
        maxW: "container.sm",
        px: "6",
      },
    },
    Button: {
      baseStyle: {
        borderRadius: "none",
      },
      variants: {
        solid: (props: Parameters<SystemStyleFunction>[0]) => {
          if (props.colorScheme === "secondary") {
            return { color: "dark.500" };
          } else if (props.colorScheme === "dark") {
            return { bg: "dark.400", color: "bright.400" };
          }
        },
        outline: {
          color: "currentColor",
          border: "2px solid",
          borderRadius: "full",
          _hover: {
            borderColor: "primary.500",
            color: "primary.500",
          },
        },
        checked: {
          color: "dark",
          bg: "bright.500",
          border: "1px solid",
          borderColor: "currentColor",
        },
        link: {
          color: "dark",
          _active: {
            color: "primary.500",
          },
          _hover: {
            color: "primary.500",
            textDecoration: "none",
          },
        },
      },
      sizes: {
        sm: {
          px: 5,
          height: 10,
          minW: 10,
        },
        md: {
          px: 8,
          height: 12,
          minW: 12,
        },
        lg: {
          h: 14,
          px: 12,
          minW: 12,
          fontSize: "md",
        },
      },
      defaultProps: {
        colorScheme: "primary",
      },
    },
    CloseButton: {
      baseStyle: {
        borderRadius: "full",
      },
    },
    Input: {
      sizes: {
        lg: {
          field: {
            px: 4,
            borderRadius: "none",
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: "dark.400",
            border: "2px",
            _placeholder: {
              color: "dark.300",
            },
            _focus: {
              borderColor: "primary.400",
            },
            _hover: {
              borderColor: "dark.400",
            },
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: "dark",
        _hover: {
          color: "primary.500",
        },
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "none",
          boxShadow: "none",
          bg: "dark.500",
          color: "bright.400",
          mx: 2,
        },
        body: {
          py: 6,
        },
      },
      defaultProps: {
        isCentered: true,
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "dark.500",
        opacity: 0.1,
      },
    },
    Tooltip: {
      baseStyle: {
        bg: "dark.400",
        color: "bright.400",
      },
    },
  },
};

export default theme;
