import React from "react";

const EthIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.6"
      d="M10.41 7.62921L4.82275 10.1702L10.4099 13.4711L15.9949 10.1702L10.4099 7.62921H10.41Z"
      fill="currentColor"
    />
    <path
      opacity="0.45"
      d="M4.82275 10.1702L10.4099 13.4711V0.900024L4.82275 10.1702Z"
      fill="currentColor"
    />
    <path
      opacity="0.7"
      d="M10.4106 0.900024V13.471L15.9956 10.1701L10.4106 0.900024Z"
      fill="currentColor"
    />
    <path
      opacity="0.45"
      d="M4.82275 11.2288L10.4099 19.1V14.5297L4.82275 11.2288Z"
      fill="currentColor"
    />
    <path
      opacity="0.8"
      d="M10.4106 14.5297V19.1L16 11.2288L10.4106 14.5297Z"
      fill="currentColor"
    />
  </svg>
);

export default EthIcon;
