import { Icon, IconProps } from "@chakra-ui/react";
import EthIcon from "components/EthIcon";
import MaticIcon from "components/MaticIcon";

type CurrencyIconProps = {
  symbol?: string;
} & IconProps;

const CurrencyIcon = ({ symbol, ...rest }: CurrencyIconProps) => (
  <Icon as={symbol === "MATIC" ? MaticIcon : EthIcon} {...rest} />
);

export default CurrencyIcon;
