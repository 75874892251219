import {
  Button,
  HStack,
  InputGroup,
  InputRightAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BigNumberInput } from "components/BigNumberInput";
import { BigNumber, BigNumberish } from "ethers";
import { formatEther } from "ethers/lib/utils";
import useAssets from "hooks/useAssets";
import useDonationActive from "hooks/useDonationActive";
import useMinimumDonation from "hooks/useMinimumDontation";
import useMint from "hooks/useMint";
import useTranslate from "hooks/useTranslate";
import { useCallback, useMemo, useState } from "react";

const DonateForm = () => {
  const translate = useTranslate();
  const minimumDonation = useMinimumDonation();
  const isDonationActive = useDonationActive();
  const { balance, symbol } = useAssets();
  const [donationAmount, setDonationAmount] = useState<BigNumberish>(
    BigNumber.from("0")
  );

  const isValid = useMemo(() => {
    if (minimumDonation) {
      return BigNumber.from(donationAmount).gte(
        BigNumber.from(minimumDonation)
      );
    }
    return false;
  }, [donationAmount, minimumDonation]);

  const handleChange = useCallback(
    (value: BigNumberish) => {
      setDonationAmount(value);
    },
    [setDonationAmount]
  );

  const handleSuccess = useCallback(() => {
    setDonationAmount(BigNumber.from("0"));
  }, [setDonationAmount]);

  const [mint, isMinting] = useMint(donationAmount, handleSuccess);

  const handleDonate = useCallback(() => {
    mint();
  }, [mint]);

  return (
    <VStack gap="6" textAlign="center" w="full">
      <VStack w="full">
        <InputGroup>
          <BigNumberInput
            min={BigNumber.from("0")}
            max={balance}
            placeholder="0.0"
            value={donationAmount || "0"}
            onChange={handleChange}
            autoFocus
            size="lg"
            isDisabled={isMinting}
          />
          <InputRightAddon
            bg="dark.500"
            border="2px solid"
            borderColor="dark.500"
            borderRadius="none"
            height="12"
            color="bright.500"
          >
            {symbol}
          </InputRightAddon>
        </InputGroup>

        {minimumDonation && !isValid && (
          <HStack>
            <Text fontSize="sm" fontWeight="semibold" color="primary.500">
              {translate("donate:minimumAmount", {
                symbol,
                amount: formatEther(minimumDonation!),
              })}
            </Text>
          </HStack>
        )}
      </VStack>

      <Button
        size="lg"
        w="full"
        isLoading={isMinting}
        isDisabled={isMinting || !isValid || !isDonationActive}
        onClick={handleDonate}
      >
        {translate("common:donate")}
      </Button>
    </VStack>
  );
};

export default DonateForm;
