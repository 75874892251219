import { useMemo } from "react";
import { useContractRead } from "wagmi";
import { BigNumberish } from "ethers";
import useNftContract from "hooks/useNftContract";

const useDonationActive = (): BigNumberish | undefined => {
  const nftContract = useNftContract();

  const [{ data: donationActiveData }] = useContractRead(
    {
      addressOrName: nftContract!.address,
      contractInterface: nftContract!.interface,
    },
    "donationActive"
  );

  return useMemo(
    () =>
      donationActiveData ? (donationActiveData as BigNumberish) : undefined,
    [donationActiveData]
  );
};

export default useDonationActive;
