import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";
import CopyInput from "components/CopyInput";
import useConnectedAccount from "hooks/useConnectedAccount";
import useTranslate from "hooks/useTranslate";

type WalletPopupProps = Omit<ModalProps, "children">;

const WalletPopup = (props: WalletPopupProps) => {
  const translate = useTranslate();
  const [address, disconnect] = useConnectedAccount();

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent w="sm" p="4">
        <ModalCloseButton />
        <ModalBody
          display="flex"
          flexDir="column"
          alignItems="stretch"
          textAlign="center"
        >
          <Heading fontSize="2xl" fontWeight="bold">
            {translate("header:wallet:title")}
          </Heading>

          <CopyInput value={address || ""} mt="8" mb="4" />

          <Button
            onClick={() => disconnect()}
            variant="solid"
            colorScheme="dark"
          >
            {translate("common:disconnect")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WalletPopup;
