import React from "react";
import {
  Button,
  Center,
  VStack,
  Container,
  Text,
  Box,
  Heading,
  Image,
  GridItem,
  Grid,
  Flex,
} from "@chakra-ui/react";
import useTranslate from "hooks/useTranslate";
import { useConnect } from "wagmi";
import useSnackbarError from "hooks/useSnackbarError";
import { publicUrl } from "config";

const ConnectPage: React.FC = () => {
  const translate = useTranslate();

  const [{ data: connectData, error }, connect] = useConnect();
  useSnackbarError(error);

  return (
    <Center flexGrow="1" pt="16" pb="16">
      <Container maxW="container.md">
        <Box
          w="full"
          gap="8"
          bg="white"
          p={{
            base: "8",
            md: "12",
          }}
        >
          <Flex
            w="full"
            maxW={{
              base: "300",
              md: "400px",
            }}
            mb="6"
            mx="auto"
          >
            <Image src={`${publicUrl}/logo_blue.png`} />
          </Flex>

          <VStack spacing="4" align="start">
            <Text color="dark.400">
              Hundreds of thousands Ukrainians fled their homes,
              living behind everything they had. Most of them crossed the Polish
              border looking for shelter and help. We created this action to
              help Ukrainian refugees crossing the Polish border, people
              escaping from the horrors&nbsp;of&nbsp;the&nbsp;war.
              <br />
              <br />
              Let's show the whole world that Web 3.0 communities are able to
              support and help those in need, we are trying to motivate, unite
              and involve people around the globe in this cause. The whole
              profit from this project will be sent to a non-profit organization
              helping victims of war that crossed the Polish border, to provide
              them with food, water and shelter.
              <br />
              <br />
              To ensure the legitimacy of this project after buying, you will be
              able to check on the blockchain where that money was transferred.
              <br />
              <br />
              In those hard times, we stand together with our friends, families,
              team members and all people affected by this conflict. We want to
              use new technologies such as NFTs in the best way we can and help
              those who need us the most.
              <br />
              <br />
            </Text>

            <Grid templateColumns="repeat(12, 1fr)" gap={0}>
              <GridItem
                colSpan={{
                  base: 12,
                  md: 7,
                }}
              >
                <Heading fontSize="lg" mb="6">
                  How can you help?
                </Heading>
                <Text color="dark.400">
                  Mint #NFTforUkraine on Ethereum or polygon network with the
                  number of tokens you want to donate for war victims. The whole
                  amount will be sent to special non-profit organisations. This
                  project is supervised by Bartek Sibiga and Jakub Chmielniak
                  from Fancy Bears Metaverse and the owners of Fanadise.
                </Text>
              </GridItem>
              <GridItem
                colSpan={{
                  base: 12,
                  md: 5,
                }}
              >
                <Flex justify="center" align="center" w="full">
                  <Image src={`${publicUrl}/nft_image.png`} />
                </Flex>
              </GridItem>
            </Grid>
          </VStack>

          <Container maxW="sm" mt={{ base: "0", md: "6" }}>
            <VStack w="full" align="stretch" spacing="4" flexShrink="0">
              {connectData.connectors.map((connector) => (
                <Button
                  key={connector.id}
                  onClick={() => connect(connector)}
                  isDisabled={!connector.ready}
                  size="lg"
                >
                  {translate("connect:button:label", {
                    connector:
                      connector.name === "Injected"
                        ? "MetaMask"
                        : connector.name,
                  })}
                </Button>
              ))}
            </VStack>
          </Container>
        </Box>
      </Container>
    </Center>
  );
};

export default ConnectPage;
