import React from "react";
import Header from "components/Header";
import AssetsProvider from "providers/AssetsProvider";
import DonateForm from "components/DonateForm";
import { Box, Center, Container, Heading, VStack } from "@chakra-ui/react";
import useTranslate from "hooks/useTranslate";
import NftContractProvider from "providers/NftContractProvider";

const HomePage: React.FC = () => {
  const translate = useTranslate();

  return (
    <NftContractProvider>
      <AssetsProvider>
        <Header />

        <Center flexGrow="1">
          <Container pt="16" pb="20" maxW="2xl">
            <VStack spacing="12" w="full">
              <Box
                bg="bright.500"
                px={{
                  base: "8",
                  sm: "16",
                }}
                py="16"
                textAlign="center"
                w="full"
              >
                <VStack spacing="8" w="full">
                  <Heading
                    size="lg"
                    dangerouslySetInnerHTML={{
                      __html: translate("donate:title"),
                    }}
                  />
                  <DonateForm />
                </VStack>
              </Box>
            </VStack>
          </Container>
        </Center>
      </AssetsProvider>
    </NftContractProvider>
  );
};

export default HomePage;
